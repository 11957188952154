@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');


#exitSurvey h2{
  font-size: 21px;
  color:rgb(15, 158, 130);
  margin-left: 20px;
  /* text-transform: uppercase; */
  letter-spacing: 3px;
}
#exitSurvey input{
  max-width: 90% !important;
  min-height: 80px;
}
#exitSurvey .tree-btn {
background-color: #0F9E82 !important;
border: 0px !important;
border-radius: 0px !important;
box-shadow: none !important;
font-size: 18px !important;
width: 100% !important;
max-width: 450px !important;
padding-top: 10px !important;
padding-bottom: 10px !important;
}
#exitSurvey .well {
background-color: white !important;
border: 0px !important;
box-shadow: none !important;
padding: 0px !important;  
}
#exitSurvey .tree-btn-container{
  justify-content: left !important;
  padding: 0px 20px !important;
}
#exitSurvey .formArea{
padding: 0px 20px !important; 
font-size: 18px;
}
#exitSurvey input[type="radio"]{
  margin: 0 10px 0 10px;
}
#exitSurvey .modal{
  display: none !important;
}
#glpCheckout .modal{
  display: none !important;
}
/* #glpCheckout input[type="number"]{
width:60px;
height: 10px;
} */
#glpCheckout p{
padding-left: 20px;
}

#glpCheckout input[type="text"]{
  background-color: #d5d5d5 !important;
  border: 1px solid #000 !important;
  border-radius: 0 !important;
  padding: .5rem !important;
  width: 450px !important;
}

.form-container {
  display: flex;
  flex-direction: column;
  gap: 0;
}

#glpCheckout input[type="checkbox" i] {
  height: 15px;
  width: 15px;
  padding-top: 2px;
}

@media only screen and (min-width: 768px) {
  #glpCheckout .mask {
    padding-left:80px;
    padding-top:30px;
  }
  #exitSurvey h2{
    font-size: 40px;
    max-width: 700px;
  }
  .form-container{
  column-count: 2;
  display: block;
  padding-left: 20px;
  }
  .questionnaire {
    display: flex !important;
    flex-direction: column;
    gap: 0;
    padding-left: 0px !important;
    
  }
  .questionnaire p{
    margin-bottom: 41px !important;
  }
  
  
  #exitSurvey .formArea{
    padding: 0px 20px !important; 
    font-size: 18px;
    width: 40% !important;
    }
    #glpCheckout input{
      max-width: 90% !important;

    }
}

#GlpCheckoutPage h2{
  font-size: 26px;
  color:rgb(15, 158, 130);
  margin-top: 100px;
  margin-left: 20px;
  text-transform: uppercase;
  letter-spacing: 3px;
}

#GlpCheckoutPage .tree-btn {
background-color: #0F9E82 !important;
border: 0px !important;
border-radius: 0px !important;
box-shadow: none !important;
font-size: 18px !important;
width: 100% !important;
max-width: 450px !important;
padding-top: 10px !important;
padding-bottom: 10px !important;
}
#GlpCheckoutPage .well {
background-color: white !important;
border: 0px !important;
box-shadow: none !important;
padding: 0px !important;  
}
#GlpCheckoutPage .tree-btn-container{
  justify-content: left !important;
  padding: 0px 20px !important;
}
#GlpCheckoutPage .formArea{
padding: 0px 20px !important; 
font-size: 18px;
}
#GlpCheckoutPage input[type="radio"]{
  margin: 0 10px 0 10px;
}
#GlpCheckoutPage .modal{
  display: none !important;
}
#glpCheckout .modal{
  display: none !important;
}
/* #glpCheckout input[type="number"]{
width:60px;
height: 10px;
} */
#glpCheckout p{
padding-left: 20px;
}

#glpCheckout input[type="text"]{
  background-color: #d5d5d5 !important;
  border: 1px solid #000 !important;
  border-radius: 0 !important;
  padding: .5rem !important;
  width: 450px !important;
}

.form-container {
  display: flex;
  flex-direction: column;
  gap: 0;
}

#glpCheckout input[type="checkbox" i] {
  height: 15px;
  width: 15px;
  padding-top: 2px;
}

@media only screen and (min-width: 768px) {
  #glpCheckout .mask {
    padding-left:80px;
    padding-top:30px;
  }
  #GlpCheckoutPage h2{
    font-size: 50px;
    max-width: 700px;
  }
  .form-container{
  column-count: 2;
  display: block;
  padding-left: 20px;
  }
  .questionnaire {
    display: flex !important;
    flex-direction: column;
    gap: 0;
    padding-left: 0px !important;
    
  }
  .questionnaire p{
    margin-bottom: 41px !important;
  }
  
  
  #GlpCheckoutPage .formArea{
    padding: 0px 20px !important; 
    font-size: 18px;
    width: 40% !important;
    }
    #glpCheckout input{
      max-width: 90% !important;
    }
}

#newrequesttrees .gradient {
  background: linear-gradient(45deg, rgba(42, 27, 161, 0.7), rgba(29, 210, 177, 0.7) 100%);
}

#newrequesttrees .request-padding {
    padding-top: 3rem;
    padding-bottom: 1rem;
  }

#newrequesttrees .request-height {
  min-height: 600px;
  height: 600px;
  border-width: 0px;
}

/* #newrequesttrees .request-section {
  padding: 0px 0px 0px 0px !important;
} */

#newrequesttrees p {
  font-size: 16px;
  font-weight: 900;
  font-family: 'Open Sans', sans-serif;
  line-height: 1.428571429;
  margin: 0 0 12px;
  color: #404b55;
  text-align: left;
  -webkit-font-smoothing: antialiased;
}

#newrequesttrees ul, ol {
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  line-height: 1.428571429;
  margin: 0 0 12px;
  color: #404b55;
  -webkit-font-smoothing: antialiased;
}

#newrequesttrees h4 {
  font-size: 20px;
  font-family: 'Open Sans', sans-serif;
  line-height: 1.1;
  color: #404b55 !important;
  -webkit-font-smoothing: antialiased;
}

#newrequesttrees .qlabel {
  color: black;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  margin-bottom: 5px;
  color: #404b55;
  -webkit-font-smoothing: antialiased;
}

#newrequesttrees textarea {
  display: inline-block;
  width: 100% important!;
}

#newrequesttrees .well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
  box-sizing: border-box;
}

#newrequesttrees .forms {
  width: 100%;
  padding: 6px 12px;
  font-size: 16px;
  line-height: 1.5;
  color: #314d68;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
}


#newrequesttrees .tree-btn {
  color: #fff;
    background-color: #D32F2F;
    border-color: #fff !important;
    font-size: 18px;
    white-space: normal !important;
    word-wrap: break-word;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 5px;
    padding-bottom: 5px;
    /* display: flex; */
    /* min-width: 15%; */
    width: 300px;
    /* width: 150px; */
    max-width: 80%;
    /* width: 90%;
    margin-bottom: 10px;
    margin-left: 5%;
    background-color: #314d68 !important; */
    margin: 6px 0;
    font-family: 'Open Sans', sans-serif;
    text-transform: none;
    line-height: 1.33;
    border-radius: 6px;
    font-weight: 500;
    text-align: center;
    justify-content: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    box-shadow: 3px 4px 5px rgba(0, 0, 0, 0.3);
}

#newrequesttrees .tree-btn-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 5px 0px;
  padding: 0px 10px;
}

#newrequesttrees .back-btn {
  color: black;
    background-color: white !important;
    border-color: rgb(204,204,204) !important;
    font-size: 15px;
    white-space: normal !important;
    word-wrap: break-word;
    padding-left: 10px !important;
    padding-right: 10px !important;
    display: block;
    width: 100%;
    font-size: 15px;
    font-family: 'Open Sans', sans-serif;
    text-transform: none;
    padding: 10px 16px;
    line-height: 1.33;
    border-radius: 4px;
    margin-bottom: 0;
    font-weight: 400;
    text-align: center;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    box-shadow: none;
}

#newrequesttrees .form-check {
  font-weight: 400 !important;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  line-height: 1.428571429;
  /* position: absolute; */
  /* top: 0;
  left: 0; */
  height: 25px;
  width: 25px;
  /* background-color: #eee; */
  /* color: #404b55; */
}

#newrequesttrees .form-check-label {
  display: block;
  padding-left: 35px;
  margin-bottom: 12px;

}

#newrequesttrees .form-control{
  height: 15px
}

#newrequesttrees .nodeQuestion {
  font-family: 'Open Sans', sans-serif;
  color: #404b55;
  font-size: 28px;
  font-weight: 500;
  line-height: 1.1;
  margin-top: 12px;
  margin-bottom: 24px;
  -webkit-font-smoothing: antialiased;
}

#newrequesttrees .formArea {
  margin-bottom: 8px;
  margin-top: 0px !important;
  display: inline;
  width:90%;
}

.likert  li {
  float: left;
  list-style-type: none;
  display: block;
  width: 100%;
  height: 100%;
}
.check{
  display: flex;
}

.ReactModal__Content{
  inset:0px !important;
}

.sigCanvas {
  outline: 5px solid black;
  width: 95%;
  min-height: 200px;
}

