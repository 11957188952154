.scheduleTable {
  width: 100%;

  border-collapse: collapse;
  text-align: left;
}

.scheduleTable th,
.scheduleTable td {
  border: 1px solid #ddd;
  padding: 0px;
  /* height: 40px; */
  /* width: 100%; */
  /* min-width: 150px; */
}

.scheduleTable thead {
  background-color: #f4f4f4;
}

.scheduleTable tbody tr:nth-child(odd) {
  background-color: #f9f9f9;
}

.scheduleTable .booked {
  background-color: red;
  color: white;
}


.calendar-navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px; /* Adjust as needed */
}

.navigate-button {
  background-color: #f0f0f0; /* Light grey, adjust as needed */
  border: 1px solid #d1d1d1; /* Slightly darker border */
  padding: 5px 10px; /* Adjust padding as needed */
  margin: 0 10px; /* Spacing between buttons and date */
  cursor: pointer; /* Changes cursor to pointer on hover */
  color:black;
}

.current-date-display {
  margin: 0 10px; /* Spacing between buttons and date */
  font-size: 1.1em; /* Slightly larger font size */
}

.scheduleTableItem {
  padding: 0px;
  line-height: 1.1;
  font-size: smaller;
}